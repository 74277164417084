import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/usr/src/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_y9dIov5OEk from "/usr/src/app/node_modules/nuxt3-vuex-module/src/plugin.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_i18n_ePQnd6dGyn from "/usr/src/app/plugins/0.i18n.js";
import _10_env_7XiIrAuV11 from "/usr/src/app/plugins/10.env.js";
import _11_sentry_client_ulmv481Jjl from "/usr/src/app/plugins/11.sentry.client.js";
import _20_api_QZLkuCPTa0 from "/usr/src/app/plugins/20.api.js";
import _30_translations_M6jWyjqXki from "/usr/src/app/plugins/30.translations.js";
import _40_link_EauhWIeOy5 from "/usr/src/app/plugins/40.link.js";
import _5_dayjs_qhyyaxkW8z from "/usr/src/app/plugins/5.dayjs.js";
import _50_matomo_client_KkIPgEEWdt from "/usr/src/app/plugins/50.matomo.client.js";
import analytics_client_mZYnNRQBH8 from "/usr/src/app/plugins/analytics.client.js";
import avatar_fDA7ztlmkq from "/usr/src/app/plugins/avatar.js";
import builder_jcMljn9YMy from "/usr/src/app/plugins/builder.js";
import carousel_1nvIEkDNzf from "/usr/src/app/plugins/carousel.js";
import chiche_override_layouts_2fV5ZwIw5s from "/usr/src/app/plugins/chiche-override-layouts.js";
import clipboard_client_xoN32NQ75x from "/usr/src/app/plugins/clipboard.client.js";
import design_system_g9ZQDUJW6y from "/usr/src/app/plugins/design-system.js";
import gtm_MVzSxF8h1r from "/usr/src/app/plugins/gtm.js";
import instagram_feed_FyKgPer0EZ from "/usr/src/app/plugins/instagram-feed.js";
import sharing_ImSZkseOd9 from "/usr/src/app/plugins/sharing.js";
import store_fixes_70XyRYkDhE from "/usr/src/app/plugins/store-fixes.js";
import sw_client_Xc5egOTazD from "/usr/src/app/plugins/sw.client.js";
import tippy_UYJccKXDFD from "/usr/src/app/plugins/tippy.js";
import toast_client_437j7GlIrd from "/usr/src/app/plugins/toast.client.js";
import validations_5wKbBvXvuW from "/usr/src/app/plugins/validations.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_y9dIov5OEk,
  chunk_reload_client_UciE0i6zes,
  _0_i18n_ePQnd6dGyn,
  _10_env_7XiIrAuV11,
  _11_sentry_client_ulmv481Jjl,
  _20_api_QZLkuCPTa0,
  _30_translations_M6jWyjqXki,
  _40_link_EauhWIeOy5,
  _5_dayjs_qhyyaxkW8z,
  _50_matomo_client_KkIPgEEWdt,
  analytics_client_mZYnNRQBH8,
  avatar_fDA7ztlmkq,
  builder_jcMljn9YMy,
  carousel_1nvIEkDNzf,
  chiche_override_layouts_2fV5ZwIw5s,
  clipboard_client_xoN32NQ75x,
  design_system_g9ZQDUJW6y,
  gtm_MVzSxF8h1r,
  instagram_feed_FyKgPer0EZ,
  sharing_ImSZkseOd9,
  store_fixes_70XyRYkDhE,
  sw_client_Xc5egOTazD,
  tippy_UYJccKXDFD,
  toast_client_437j7GlIrd,
  validations_5wKbBvXvuW
]