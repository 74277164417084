import { reactive } from "vue"
import template from "es6-template-strings";

export default defineNuxtPlugin(async (NuxtApp) => {
  const initialLang = NuxtApp.$i18n.locale.value ? NuxtApp.$i18n.locale.value : NuxtApp.$i18n.locale;
  const observable = reactive({
    lang: initialLang || 'fr',
    translations: null
  });

  function replaceVars (str, data) {
    let translated;
    try {
      translated = template(str, data);
    }
    catch (e) {
      translated = null;
    }
    return translated;
  }
  function translate (key, data) {
    let lang = observable.lang;
    let translations = observable.translations;
    if (translations && translations[key] && translations[key][lang]) {
      return replaceVars(translations[key][lang], data) || key;
    }
    return replaceVars(key, data) || key;
  }
  function translateContext (key, context, data) {
    let translateKey = key + ' || ' + context;
    let translatedValue = translate(translateKey, data);
    return translatedValue.replace(' || ' + context, '');
  }
  function getTranslationObject (translations) {
    let translationObject = {};
    translations.forEach((translation) => {
      let key = translation.key;
      if (translation.context) {
        key += ' || ' + translation.context;
      }
      translationObject[key] = translation.langs;
    });
    return translationObject;
  }

  function setTranslations (translations) {
    observable.translations = getTranslationObject(translations);
  }

  const setLang = async (locale) => {
    const currentLang = observable.lang;
    const lang = locale.value ? locale.value : locale;
    if (lang) {
      console.log('set lang observable', currentLang, lang);
      if (currentLang !== lang) {
        observable.lang = lang;
        NuxtApp.$dayjs.locale(lang);
        await NuxtApp.$i18n.setLocale(lang);
        console.log('set lang page', currentLang, lang);
        NuxtApp.$router.replace(NuxtApp.$localePath(NuxtApp.$router.currentRoute.path));
      }
    }
  };


  NuxtApp.vueApp.mixin({
    computed: {
      lang: () => observable.lang
    },
    methods: {
      __: (string, data) => translate(string, data),
      _x: (string, context, data) => translateContext(string, context, data)
    }
  });

  await NuxtApp.$api.Translation.query({module: ['events', 'mks-vue'], limit: 0})
    .then((response) => {
      setTranslations(response.data);
    });
  return {
    provide: {
      getLang: () => observable.lang,
      setLang,
      __: (string, data) => translate(string, data),
      _x: (string, context, data) => translateContext(string, context, data),
      localizeData: (data, lang) => {
        const localizedLang = lang || data.lang;
        const localizedData = Object.assign({}, data);
        ['title', 'description', 'cover', 'address'].forEach(item => {
          if (!data[item]) return;
          if (localizedData[item][localizedLang]) return;
          localizedData[item] = {};
          localizedData[item][localizedLang] = data[item];
        });
        return localizedData;
      },
    }
  };
});

