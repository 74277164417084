<template>
  <Section background="primary" color="neutral-100" class="mks-component buzzing-articles" smallContent radius="bottom">
    <Heading 
      class="buzzing-articles__heading"
      tag="h2" 
      tagClass="h1"
      color="inherit"
      :textBefore="textBeforeBean"
      :textBean="textBean"
      :textAfter="textAfterBean"
    />
    <ol class="buzzing-articles__list ">
      <li v-for="(article, index) in items" :key="index">
        <div class="buzzing-articles__item">
          <span class="buzzing-article__counter">
            <template v-if="index + 1 < 10">0</template>{{ index + 1 }}
          </span>
          <ArticleTeaser :content="article" variant="line"/>
        </div>
      </li>
    </ol>
    <mks-link type="menu" to="/media" class="buzzing-articles__cta">
      <mks-button type="secondary" size="small">Voir tous les articles</mks-button>
    </mks-link>
  </Section>
</template>
<script>
import { useAsyncData } from 'nuxt/app';
export default defineNuxtComponent({
  props: {
    numberOfArticles: {
      type: Number,
      default: 6
    },
    articles: {
      type: Array,
    },
    textBeforeBean: {
      type: String
    },
    textBean: {
      type: String
    },
    textAfterBean: {
      type: String
    }
  },
  data() {
    return {
      items: []
    }
  },
  async setup(props) {
    const nuxtApp = useNuxtApp();
    const data = {};
    // getAll - limit = 5 - sortby count

    try {
      const twoWeeksAgo = new Date(nuxtApp.$dayjs().subtract(5, 'week'));

      const articles = await useAsyncData(() => nuxtApp.$builder.getAll('article', {
          options: {
            sort: '-count',
            limit: props.numberOfArticles
          },
          query: {
            "firstPublished.$gte": twoWeeksAgo.toISOString()
          }
        })
      );

      data.items = articles.data._value.results;
    }
    catch (err) {
      console.log(err);
    }

    return data;
    
  },
  created () {
    if (this.items) return;
    else if (this.articles && this.articles.length > 0) {
      this.formatArticlesFromBuilder();
    }
    
  },
  methods: {
    formatArticlesFromBuilder() {
      this.items = this.articles.map(i => i.content);
    }
  }
});
</script>
<style lang="scss">
.buzzing-articles__heading {
  max-width: 500px;
  margin-top: 0;

  @include breakpoint('small') {
    margin-top: $space-xl;
  }
}

.buzzing-articles__list {
  list-style-type: none;
  margin-bottom: $space-xl;
  padding: 0 $space-xs;

  @media (min-width:430px) {
    padding: 0 $space-xl;
  }

  @include breakpoint('small') {
    columns: 2;
    column-gap: $space-xl;
    padding: 0;

    li {
      break-inside: avoid-column;
      &:first-child {
        padding-top: $space-xxl;
      }
    }
  }
}

.buzzing-articles__item {
  position: relative;
  break-inside: avoid;
}

.buzzing-article__counter {
  font-family: 'SP Malton SANS', 'Raleway';
  font-size: 50px;
  margin-left: 0;
  margin-bottom: -20px;
  display: inline-block;
  transform: translate(-20px, 10px);
  -webkit-column-break-inside: avoid-column;
  page-break-inside: avoid-column;
  break-inside: avoid-column;
  text-shadow: 0 0 2px #fff;
  color: var(--color-primary);

  @include breakpoint('small') {
    font-size: 80px;
    transform: translate(-20px, 20px);
  }
}

.buzzing-articles__cta {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: $space-m;
}
</style>